import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import loadable from '@loadable/component';
import makeStyles from '@material-ui/core/styles/makeStyles';

import linkResolver from '~src/prismic/utils/link-resolver';
import { ButtonSubmit } from '~components/form-buttons';
import LaptopNotFoundPageImg from '~images/404/404-laptop.png';
import componentResolver from '~src/prismic/utils/component-resolver';

const FlowLayout = loadable(() => import('~components/FlowLayout'));
const Page = loadable(() => import('~layout/Page'));

const useStyles = makeStyles({
  media: {
    display: 'flex',
    maxWidth: '100%'
  }
});

const browser = typeof window !== 'undefined' && window;

const NotFoundPage = () => {
  const classes = useStyles();
  return (
    browser && (
      <Page>
        <FlowLayout title="Oops!" subtitle="We can't seem to find the page you're looking for.">
          <>
            <ButtonSubmit type="button" component={GatsbyLink} to="/">
              Back to Home
            </ButtonSubmit>
          </>
          <>
            <img src={LaptopNotFoundPageImg} alt="" className={classes.media} />
          </>
        </FlowLayout>
      </Page>
    )
  );
};

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryName: 'aleph-beta',
    linkResolver,
    componentResolver
  }
]);
